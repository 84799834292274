// eslint-disable-next-line @typescript-eslint/no-var-requires
const path = require('path');
module.exports = {
  debug: process.env.NODE_ENV === 'development',
  i18n: {
    defaultLocale: 'uk',
    locales: ['uk', 'en'],
    localeDetection: false,
  },
  localePath: path.resolve('./public/locales'),
};
