import * as React from 'react';
import { PaletteMode, ThemeProvider, useMediaQuery } from '@mui/material';
import { theme, ThemeContextInterface } from './helpers';

export const ThemeContext = React.createContext<ThemeContextInterface>(
  {} as ThemeContextInterface
);

const ThemeContextProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [themeMode, setThemeMode] = React.useState<PaletteMode>(
    prefersDarkMode ? 'dark' : 'light'
  );

  const saveThemeMode = (value: PaletteMode) => {
    localStorage.setItem('themeMode', value);
    setThemeMode(value);
  };

  const contextValue = {
    isLightTheme: themeMode === 'light',
    isDarkTheme: themeMode === 'dark',
    themeMode,
    setThemeMode: saveThemeMode,
  };

  React.useEffect(() => {
    const savedTheme = localStorage.getItem('themeMode');
    if (savedTheme) setThemeMode(savedTheme as PaletteMode);
  }, []);

  return (
    <ThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme[themeMode]}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
