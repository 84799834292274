import { createTheme } from '@mui/material/styles';
import { PaletteMode, Theme } from '@mui/material';

export interface ThemeContextInterface {
  isLightTheme: boolean;
  isDarkTheme: boolean;
  themeMode: PaletteMode;
  setThemeMode: (value: PaletteMode) => void;
}

const darkTheme = createTheme({ palette: { mode: 'dark' } });
const lightTheme = createTheme({ palette: { mode: 'light' } });

export const theme: { [key in PaletteMode]: Theme } = {
  dark: darkTheme,
  light: lightTheme,
};
