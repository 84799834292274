import * as React from 'react';
import { Language, LanguageContextInterface } from './helpers';
import { useRouter } from 'next/router';

export const LanguageContext = React.createContext<LanguageContextInterface>(
  {} as LanguageContextInterface
);

const LanguageContextProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;

  const [language, setLanguage] = React.useState<Language>(locale as Language);

  const saveLanguage = (value: Language) => {
    setLanguage(value);
    router.push({ pathname, query }, asPath, { locale: value });
  };

  const contextValue = {
    language,
    setLanguage: saveLanguage,
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
