import Head from 'next/head';
import { AppProps as NextAppProps } from 'next/app';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { createEmotionCache } from 'utils';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from '../../next-i18next.config.js';
import { LanguageContextProvider, ThemeContextProvider } from 'contexts';
import Router from 'next/router';
import { default as LoadingProgress } from 'nprogress';
import './index.css';

interface AppProps extends NextAppProps {
  emotionCache?: EmotionCache;
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

LoadingProgress.configure({
  showSpinner: true,
});

Router.events.on('routeChangeStart', () => LoadingProgress.start());
// Router.events.on('routeChangeComplete', () => LoadingProgress.done());
// Router.events.on('routeChangeError', () => LoadingProgress.done());

const App = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: AppProps) => {
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title>Loskut shop</title>
        <meta name="description" content="Description" />
        <meta name="keywords" content="Keywords" />
        <link rel="manifest" href="/manifest.json" />
        <link
          href="/icons/favicon-16x16.png"
          rel="icon"
          type="image/png"
          sizes="16x16"
        />
        <link
          href="/icons/favicon-32x32.png"
          rel="icon"
          type="image/png"
          sizes="32x32"
        />
        <link rel="apple-touch-icon" href="/apple-icon.png"></link>
      </Head>
      <ThemeContextProvider>
        <LanguageContextProvider>
          <CssBaseline />
          <Component {...pageProps} />
          <style jsx global>{`
            html,
            body,
            body > div:first-child,
            div#__next,
            div#__next > div {
              height: 100%;
            }
          `}</style>
        </LanguageContextProvider>
      </ThemeContextProvider>
    </CacheProvider>
  );
};

export default appWithTranslation(App, nextI18NextConfig);
